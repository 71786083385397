@font-face {
    font-family: 'e-Ukraine-Thin';
    src: url('/fonts/e-Ukraine-Thin.ttf');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'e-Ukraine-Thin', serif !important;
    color: #000;
    margin: 0;
    padding: 0;
}

.img-text-block {
    display: flex;
    align-items: center;
    margin: 20px;
}
.img-text-block>div {
    padding: 5px;
}
.img-in-text {
    display: flex;
    align-items: center;
}
.logo {
    max-width: 100px;
    margin-right: 20px;
    opacity: 0.5;
    transition: opacity 0.3s ease;
}
.logo:hover {
    opacity: 1;
}
/*h1, .header-title {*/
/*    font-family: 'e-Ukraine-Light', sans-serif !important;*/
/*    font-size: 30px;*/
/*    color: #1b8bcb;*/
/*}*/
/*h2 {*/
/*    font-family: 'e-Ukraine-Light', sans-serif;*/
/*    font-size: 25px;*/
/*}*/
/*h3, h4 {*/
/*    font-family: 'e-Ukraine light', sans-serif;*/
/*    font-size: 20px;*/
/*}*/
/*h5, h6 {*/
/*    font-family: 'e-Ukraine-Light', sans-serif !important;*/
/*    font-size: 13px;*/
/*}*/
/*p, .text {*/
/*    font-family: 'e-Ukraine-Light', sans-serif !important;*/
/*    font-size: 12px;*/
/*}*/
/*.highlight {*/
/*    color: #ffd500;*/
/*}*/
/*.container {*/
/*    padding: 20px;*/
/*}*/
/*.section {*/
/*    margin-bottom: 20px;*/
/*}*/
/*body {*/
/*    font-family: 'e-Ukraine-Light', sans-serif;*/
/*}*/

.bottom-border {
    width: 100%;
    height: 4px;
    background-color: #1b8bcb;
}
.yellow-title{
    color: #3a3a3a;
    background-color: rgb(255, 213, 0);
}
.yellow-border{
    border: 2px solid rgb(253, 212, 0);
}
.grey-title{
    color: #3a3a3a;
    background-color: #e0e0e0;
}
.grey-border{
    border: 2px solid #e0e0e0;
}
.btn-custom-grey{
    color: #000000;
    border: 2px solid rgb(253, 212, 0);
    font-weight: 800;
}
.btn-custom-grey:hover{
    color: #ffffff;
    background-color: #1b8bcb;
}
.blue-title{
    color: #ffffff;
    background-color: #1b8bcb;
}
.blue-border{
    border: 2px solid #1b8bcb;
}
#breadcrumbs-size {
    font-size: xx-small;
}
/*жовтий колір меню*/
.nav-item, .dropdown-item {
    color: #000;
    font-size: small;
}
.nav-item:hover {
    border-radius: 2px;
    /*background-color: #f5f5f5;*/
}
.nav-link:hover, .nav-link:focus, .nav-link.active, .dropdown-item:hover, .dropdown-item:focus, .dropdown-item.active {
    border-bottom: 1px solid #ffe500;
    font-weight: bolder;
    /*border-radius: 6px;*/
    /*background-color: #ffe500;*/
    color: #000000;
}
.nav-item {
    /*transition: color 0.2s, background-color 0.2s;*/
}
.dropdown-item {
    transition: color 0.2s, background-color 0.2s;
}
.nav-link {
    transition: color 0.5s;
    color: #817a7a;
}
.bg-dark-blue {
    background-color: #304a67;
}
.bg-light-grey {
    background-color: #efefef;
}
.icon-service {
    max-width: 100px;
    margin-right: 0px;
    opacity: 0.7;
}
.icon-title {
    max-width: 5%;
    margin-right: 0px;
    opacity: 0.8;
}
.icon-opacity {
    opacity: 0.5;
}
.date-opacity {
    opacity: 0.3;
    font-size: x-small;
}
.news-img {
    max-width: 20%;
    height: auto;
}
#map {
    height: 400px;
}
#anticor-law-links a{
    text-decoration: none;
    color: dodgerblue;
}

#anticor-law-links a:hover{
    text-decoration: none;
    color: #088bcc;
}
#anticor-law-links2 a{
    text-decoration: none;
    color: dodgerblue;
}

#anticor-law-links2 a:hover{
    text-decoration: none;
    color: #088bcc;
}
.accordion-button{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}
